body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.logo {
  width: 100px;
  height: 100px;
  margin: 20px;
}

.logo img {
  width: 100%;
  height: 100%;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #333;
  color: #fff;
}

.nav-brand {
  margin-right: 2rem;
}

.nav-logo {
  width: 40px;
  height: 40px;
  margin-right: 1rem;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  border-left: 10px solid #333;
  border-right: 10px solid #333;
}

.nav-link {
  color: #fff;
  margin-left: 25px;
  text-decoration: none;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: #ccc;
}

.nav-toggle {
  display: none;
}

.nav-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.nav-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-position: center;
}

/* Responsive design */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
  .nav-toggle {
    display: block;
  }
  .nav-btn {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
  .nav-btn:hover {
    background-color: #444;
  }
  .nav-btn:focus {
    outline: none;
  }
  .nav-links.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: #333;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
}

.hero {
  background-image: url("logo .png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  align-items: center;
  display: grid;
  justify-content: center;
  color: #ffffff;
}

.hf h1 {
  font-size: 48px;
  margin-bottom: 400px;
  margin-left: 35px;
  text-align: center;
}

.hf p {
  font-size: 30px;
  position: relative;
  top: -185px;
  left: 200px;
  font-style: oblique;
  color: #ffd700;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.feature {
  width: 30%;
  margin: 20px;
  background-color: #dddddd;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 20px;
}

.feature-icon {
  font-size: 48px;
  color: #ffd700;
  margin-bottom: 20px;
}

.feature h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.feature p {
  font-size: 18px;
  margin-bottom: 20px;
}

.testimonial {
  background-color: #f7f7f7;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin: 20px;
}

.testimonial p {
  font-size: 18px;
  margin-bottom: 20px;
}

.testimonial-author {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.call-to-action {
  background-color: #ffd700;
  padding: 20px;
  text-align: center;
  margin: 20px;
}

.call-to-action:hover {
  cursor: pointer;
}

.call-to-action a {
  color: #ffffff;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
}

footer {
  background-color: #000000;
  color: #ffffff;
  padding: 20px;
  text-align: center;
}

footer a {
  color: #ffffff;
  text-decoration: none;
}

/* Mobile responsive styles */
@media (max-width: 480px) {
  .hero {
    height: 50vh;
  }
  .hf h1 {
    font-size: 24px;
    margin-bottom: 100px;
    margin-left: 10px;
  }
  .hf p {
    font-size: 18px;
    position: relative;
    top: -50px;
    left: 10px;
  }
  .features {
    flex-direction: column;
  }
  .feature {
    width: 100%;
    margin: 10px;
  }
  .testimonial {
    margin: 10px;
  }
  .call-to-action {
    margin: 10px;
  }
  footer {
    padding: 10px;
  }
}

/* Tablet responsive styles */
@media (max-width: 768px) {
  .hero {
    height: 75vh;
  }
  .hf h1 {
    font-size: 36px;
    margin-bottom: 200px;
    margin-left: 20px;
  }
  .hf p {
    font-size: 24px;
    position: relative;
    top: -100px;
    left: 20px;
  }
  .features {
    flex-wrap: wrap;
  }
  .feature {
    width: 45%;
    margin: 20px;
  }
  .testimonial {
    margin: 20px;
  }
  .call-to-action {
    margin: 20px;
  }
  footer {
    padding: 20px;
  }
}

/* Desktop responsive styles */
@media (min-width: 1024px) {
  .hero {
    height: 100vh;
  }
  .hf h1 {
    font-size: 48px;
    margin-bottom: 400px;
    margin-left: 35px;
  }
  .hf p {
    font-size: 30px;
    position: relative;
    top: -185px;
    left: 200px;
  }
  .features {
    flex-wrap: wrap;
  }
  .feature {
    width: 30%;
    margin: 20px;
  }
  .testimonial {
    margin: 20px;
  }
  .call-to-action {
    margin: 20px;
  }
  footer {
    padding: 20px;
  }
}