.contact-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-info {
    margin-bottom: 20px;
  }
  
  .contact-info ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .contact-info li {
    margin-bottom: 10px;
  }
  
  .contact-info i {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .social-media {
    margin-bottom: 20px;
  }
  
  .social-media ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .social-media li {
    margin-bottom: 10px;
  }
  
  .social-media a {
    color: #0b68b9;
    text-decoration: none;
  }
  
  .social-media a:hover {
    color: #172eb0;
  }
  
  .contact-form {
    margin-bottom: 20px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 10px;
  }
  
  .contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
  }
  
  .contact-form button[type="submit"] {
    background-color: #ffd700;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-form button[type="submit"]:hover {
    background-color: #cdae00;
  }